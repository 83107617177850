<script lang="tsx">
import { defineComponentCoreIcon } from '@core/app/components/core/icon/CoreIcon.vue'

export default defineComponentCoreIcon(
    <svg viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M8.68352 0C3.89632 0 0 3.89632 0 8.68352C0 16.1152 8.07168 23.5802 8.41728 23.895C8.49408 23.9642 8.5888 24 8.68608 24C8.78336 24 8.87808 23.9642 8.95488 23.895C9.29792 23.5802 17.3696 16.1152 17.3696 8.68352C17.367 3.89632 13.4707 0 8.68352 0ZM4.31616 8.68352C4.31616 6.27456 6.27712 4.31616 8.68352 4.31616C11.0899 4.31616 13.0509 6.27712 13.0509 8.68352C13.0509 11.0899 11.0899 13.0509 8.68352 13.0509C6.27712 13.0509 4.31616 11.095 4.31616 8.68352Z"
            fill="currentColor"
        />
    </svg>
)

</script>

<style lang="scss" scoped>

</style>
